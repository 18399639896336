<div class="container-home">
  <app-font-image></app-font-image>


  <div class="name-zone">
    <h1>Maëva Trivino</h1>
    <h2>{{'Home.Title' | translate}}</h2>

    <div class="logos">
      <a href="https://www.linkedin.com/in/maëva-trivino-968380150" target="_blank"><i class="fab fa-linkedin fa-2x"></i></a>
      <a href="https://github.com/maevatrivino" target="_blank"><i class="fab fa-github fa-2x"></i></a>
      <a href="https://maevatrivino.itch.io/" target="_blank"><i class="fab fa-itch-io fa-2x"></i></a>
    </div>
  </div>

  <app-footer></app-footer>
</div>

<app-slider-menu></app-slider-menu>


