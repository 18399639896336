<p-dialog [modal]="true" [(visible)]="visible" [style]="{'width': '500px', 'overflow': 'auto'}"
          [draggable]="false" [responsive]="true"  [dismissableMask]="true" (onHide)="onHide()">
  <p-header>
    Winds & Leaves
  </p-header>

  <p align="justify" [innerHtml]="'ProfesionnalProjects.WindsAndLeaves.Description' | translate"></p>

  <p>
    <strong>{{'ProfesionnalProjects.WindsAndLeaves.SteamStore' | translate }} </strong> <a href="https://store.steampowered.com/app/1783750/Winds__Leaves/" target="_blank">https://store.steampowered.com/app/1783750/Winds__Leaves/</a>
  </p>

</p-dialog>
