import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-mage-uqac',
  templateUrl: './mage-uqac.component.html',
  styleUrls: ['./mage-uqac.component.scss']
})
export class MageUqacComponent implements OnInit {

  @Input()
  public visible: boolean;
  @Output()
  public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  onHide(){
    this.visible = false;
    this.visibleChange.emit(this.visible);
  }

}
