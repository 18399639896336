import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-broken-edge',
  templateUrl: './broken-edge.component.html',
  styleUrls: ['./broken-edge.component.scss']
})
export class BrokenEdgeComponent implements OnInit {

  @Input()
  public visible: boolean;
  @Output()
  public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  onHide(){
    this.visible = false;
    this.visibleChange.emit(this.visible);
  }

}
