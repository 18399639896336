import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-cae',
  templateUrl: './cae.component.html',
  styleUrls: ['./cae.component.scss']
})
export class CAEComponent implements OnInit {

  @Input()
  public visible: boolean;
  @Output()
  public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  onHide(){
    this.visible = false;
    this.visibleChange.emit(this.visible);
  }

}
