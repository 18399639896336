<p-dialog [modal]="true" [(visible)]="visible" [style]="{'width': '500px', 'overflow': 'auto'}"
          [draggable]="false" [responsive]="true"  [dismissableMask]="true" (onHide)="onHide()">
  <p-header>
    {{'PersonnalProjects.TutorialReact.Title' | translate }}
  </p-header>

  <p align="justify" [innerHtml]="'PersonnalProjects.TutorialReact.Description1' | translate"></p>
  <p align="justify">
    <strong>{{'PersonnalProjects.TutorialReact.Teammates' | translate }} </strong>Fabien Cellard
  </p>
  <p align="justify" [innerHtml]="'PersonnalProjects.TutorialReact.Description2' | translate"></p>
  <strong>{{'PersonnalProjects.TutorialReact.ExecutableLink' | translate }} </strong><a href="https://github.com/maevatrivino/tuto_react_native" target="_blank">https://github.com/maevatrivino/tuto_react_native</a>
</p-dialog>
