<div class="container-projets">
  <app-font-image></app-font-image>

  <div class="title-page">
    <h1>{{'SliderMenu.ProfessionalProjects' | translate }}</h1>
  </div>

  <div class="list-cards">

    <p-card header="{{'PersonnalProjects.And.Title' | translate }}"
            subheader="{{'PersonnalProjects.And.SubTitle' | translate }}"
            [style]="{width : '320px', height: '320px'}"
            styleClass="ui-card-shadow"
            (click)="clickAndProjectCard()">
      <p-header>
        <img src="../../../assets/projets/AND.png">
      </p-header>
    </p-card>

    <p-card header="{{'PersonnalProjects.TutorialReact.Title' | translate }}"
            subheader="{{'PersonnalProjects.TutorialReact.SubTitle' | translate }}"
            [style]="{width : '320px', height: '320px'}"
            styleClass="ui-card-shadow"
            (click)="clickTutoReactCard()">
      <p-header>
        <img src="../../../assets/projets/react.png">
      </p-header>
    </p-card>

    <p-card header="{{'PersonnalProjects.GranniesWar.Title' | translate }}"
            subheader="{{'PersonnalProjects.GranniesWar.SubTitle' | translate }}"
            [style]="{width : '320px', height: '320px'}"
            styleClass="ui-card-shadow"
            (click)="clickGranniesWarCard()">
      <p-header>
        <img src="../../../assets/projets/grannies_war.png">
      </p-header>
    </p-card>

    <p-card header="{{'PersonnalProjects.ElixirOfLove.Title' | translate }}"
            subheader="{{'PersonnalProjects.ElixirOfLove.SubTitle' | translate }}"
            [style]="{width : '320px', height: '320px'}"
            styleClass="ui-card-shadow"
            (click)="clickElixirOfLoveCard()">
      <p-header>
        <img src="../../../assets/projets/elixir_of_love.png">
      </p-header>
    </p-card>

    <p-card header="{{'PersonnalProjects.Yapalfeu.Title' | translate }}"
            subheader="{{'PersonnalProjects.Yapalfeu.SubTitle' | translate }}"
            [style]="{width : '320px', height: '320px'}"
            styleClass="ui-card-shadow"
            (click)="clickYapalfeuCard()">
      <p-header>
        <img id="img-yapalfeu" src="../../../assets/projets/yapalfeu.jpeg">
      </p-header>
    </p-card>

  </div>

  <app-and [(visible)]="visibleAndModal"></app-and>
  <app-tuto-react [(visible)]="visibleTutoReactModal"></app-tuto-react>
  <app-grannies-war [(visible)]="visibleGranniesWarModal"></app-grannies-war>
  <app-elixir-of-love [(visible)]="visibleElixirOfLoveModal"></app-elixir-of-love>
  <app-yapalfeu [(visible)]="visibleYapalfeuModal"></app-yapalfeu>

  <app-footer></app-footer>


</div>

<app-slider-menu></app-slider-menu>
