<div class="container-engagement">
  <app-font-image></app-font-image>

  <div class="title-page">
    <h1>{{'SliderMenu.Volunteering' | translate }}</h1>
  </div>

  <div class="list-cards">
    <p-card header="{{'EngagementAssociatif.AEIUQAC' | translate }}"
            subheader="{{'EngagementAssociatif.AEIUQACDate' | translate }}"
            [style]="{width : '320px', height: '450px'}"
            styleClass="ui-card-shadow">
      <p-header>
        <img src="../../../assets/engagement-asso/LogoAEI_big.png">
      </p-header>
    </p-card>

    <p-card header="{{'EngagementAssociatif.ASLyon1ClubSport' | translate }}"
            subheader="{{'EngagementAssociatif.ASLyon1ClubSportDate' | translate }}"
            [style]="{width : '320px', height: '450px'}"
            styleClass="ui-card-shadow">
      <p-header>
        <img src="../../../assets/engagement-asso/cds.jpeg">
      </p-header>
    </p-card>

    <p-card header="{{'EngagementAssociatif.ASLyon1Basket' | translate }}"
            subheader="{{'EngagementAssociatif.ASLyon1BasketDate' | translate }}"
            [style]="{width : '320px', height: '450px'}"
            styleClass="ui-card-shadow">
      <p-header>
        <img src="../../../assets/engagement-asso/section_basket.jpeg">
      </p-header>
    </p-card>

    <p-card header="{{'EngagementAssociatif.ZupDeCo' | translate }}"
            subheader="{{'EngagementAssociatif.ZupDeCoDate' | translate }}"
            [style]="{width : '320px', height: '450px'}"
            styleClass="ui-card-shadow">
      <p-header>
        <img src="../../../assets/engagement-asso/zup_de_co.png">
      </p-header>
    </p-card>
  </div>

  <app-footer></app-footer>
</div>

<app-slider-menu></app-slider-menu>
