import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { provideHttpClient } from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SliderMenuComponent } from './utils/slider-menu/slider-menu.component';
import { HomeComponent } from './pages/home/home.component';
import { FormationsComponent } from './pages/formations/formations.component';
import { FontImageComponent } from './utils/font-image/font-image.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CardModule} from 'primeng/card';
import {ButtonModule} from 'primeng/button';
import { FooterComponent } from './utils/footer/footer.component';
import { CompetencesComponent } from './pages/competences/competences.component';
import { ExperiencesProComponent } from './pages/experiences-pro/experiences-pro.component';
import { ProjetsComponent } from './pages/projets/projets.component';
import { EngagementAssoComponent } from './pages/engagement-asso/engagement-asso.component';
import { ProgressBarComponent } from './utils/progress-bar/progress-bar.component';
import { AndComponent } from './pages/projets/and/and.component';
import {DialogModule} from 'primeng/dialog';
import { TutoReactComponent } from './pages/projets/tuto-react/tuto-react.component';
import { GranniesWarComponent } from './pages/projets/grannies-war/grannies-war.component';
import { ElixirOfLoveComponent } from './pages/projets/elixir-of-love/elixir-of-love.component';
import { YapalfeuComponent } from './pages/projets/yapalfeu/yapalfeu.component';
import { WindsAndLeavesComponent } from './pages/projets-pro/winds-and-leaves/winds-and-leaves.component';
import { IntermarcheComponent } from './pages/experiences-pro/intermarche/intermarche.component';
import { TrebuchetComponent } from './pages/experiences-pro/trebuchet/trebuchet.component';
import { CollegeBoisBoulogneComponent } from './pages/experiences-pro/college-bois-boulogne/college-bois-boulogne.component';
import { MageUqacComponent } from './pages/experiences-pro/mage-uqac/mage-uqac.component';
import { ApolloComponent } from './pages/experiences-pro/apollo/apollo.component';
import { ProjetsProComponent } from './pages/projets-pro/projets-pro.component';
import { BrokenEdgeComponent } from './pages/projets-pro/broken-edge/broken-edge.component';
import { CAEComponent } from './pages/experiences-pro/cae/cae.component';

export function httpTranslateLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    SliderMenuComponent,
    HomeComponent,
    FormationsComponent,
    FontImageComponent,
    FooterComponent,
    CompetencesComponent,
    ExperiencesProComponent,
    ProjetsComponent,
    EngagementAssoComponent,
    ProgressBarComponent,
    AndComponent,
    TutoReactComponent,
    GranniesWarComponent,
    ElixirOfLoveComponent,
    YapalfeuComponent,
    WindsAndLeavesComponent,
    IntermarcheComponent,
    TrebuchetComponent,
    CollegeBoisBoulogneComponent,
    MageUqacComponent,
    ApolloComponent,
    ProjetsProComponent,
    BrokenEdgeComponent,
    CAEComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CardModule,
    DialogModule,
    ButtonModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: httpTranslateLoaderFactory,
          deps: [HttpClient]
      }
  })
  ],
  providers: [provideHttpClient()],
  bootstrap: [AppComponent]
})
export class AppModule { }
