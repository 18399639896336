 <input id="hamburger" class="hamburger" type="checkbox"/>
  <label class="hamburger" for="hamburger">
    <i></i>
  </label>
  <section class="drawer-list">
    <div class="general-menu">
      <ul>
        <li><a (click)="getOnHomePage()">{{'SliderMenu.Home' | translate }}</a></li>
        <li><a (click)="getOnFormationsPage()">{{'SliderMenu.Schooling' | translate }}</a></li>
        <li><a (click)="getOnCompetencesPage()">{{'SliderMenu.Skills' | translate }}</a></li>
        <li><a (click)="getOnExperiencesProPage()">{{'SliderMenu.ProfessionalExperiences' | translate }}</a></li>
        <li><a (click)="getOnProjetsProPage()">{{'SliderMenu.ProfessionalProjects' | translate }}</a></li>
        <li><a (click)="getOnProjetsPage()">{{'SliderMenu.PersonnalProjects' | translate }}</a></li>
        <li><a (click)="getOnEngagementAssoPage()">{{'SliderMenu.Volunteering' | translate }}</a></li>
        <br/>
        <li><a (click)="translateLanguageTo(valueDontUse)">{{'SliderMenu.Language' | translate }}</a></li>
      </ul>
    </div>
  </section>
