import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './pages/home/home.component';
import {FormationsComponent} from './pages/formations/formations.component';
import {CompetencesComponent} from './pages/competences/competences.component';
import {ExperiencesProComponent} from './pages/experiences-pro/experiences-pro.component';
import {ProjetsComponent} from './pages/projets/projets.component';
import {EngagementAssoComponent} from './pages/engagement-asso/engagement-asso.component';
import {ProjetsProComponent} from "./pages/projets-pro/projets-pro.component";


const routes: Routes = [
  { path: '', component: HomeComponent},
  { path: 'formations', component: FormationsComponent},
  { path: 'competences', component: CompetencesComponent},
  { path: 'experiencespro', component: ExperiencesProComponent},
  { path: 'projets', component: ProjetsComponent},
  { path: 'projetspro', component: ProjetsProComponent},
  { path: 'engagementasso', component: EngagementAssoComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
