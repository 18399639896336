import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-projets-pro',
  templateUrl: './projets-pro.component.html',
  styleUrls: ['./projets-pro.component.scss']
})
export class ProjetsProComponent implements OnInit {

  public visibleWindsAndLeavesModal = false;
  public visibleBrokenEdgeModal = false;

  constructor() { }

  ngOnInit(): void {
  }

  clickWindsAndLeavesCard(){
    this.visibleWindsAndLeavesModal = true;
  }

  clickBrokenEdgeCard(){
    this.visibleBrokenEdgeModal = true;
  }

}
