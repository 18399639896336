import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {

  @Input() pourcentageValue: number;
  @Input() heightValue: number;

  public transformValueInPourcentage: string;
  public transformHeightInPx: string;


  constructor() { }

  ngOnInit(): void {
    this.transformHeightInPx = this.heightValue.toString() + 'px';
    this.transformValueInPourcentage = this.pourcentageValue.toString() + '%';

    const pourcentage = this.pourcentageValue / 100;
    const style = document.documentElement.style;

    // style.setProperty('--widthMax', pourcentage);
  }

}
