<p-dialog [modal]="true" [(visible)]="visible" [style]="{'width': '500px', 'overflow': 'auto'}"
          [draggable]="false" [responsive]="true"  [dismissableMask]="true" (onHide)="onHide()">
  <p-header>
    {{'PersonnalProjects.ElixirOfLove.Title' | translate }}
  </p-header>

  <p align="justify" [innerHtml]="'PersonnalProjects.ElixirOfLove.Description1' | translate"></p>
  <ul>
    <li>
      Baptiste Rey
    </li>
    <li>
      Jean Farines
    </li>
    <li>
      Damien Galochet
    </li>
  </ul>
  <p align="justify" [innerHtml]="'PersonnalProjects.ElixirOfLove.Description2' | translate"></p>
  <strong>{{'PersonnalProjects.ElixirOfLove.ExecutableLink' | translate }}</strong> <a href="https://maevatrivino.itch.io/elixir-of-love" target="_blank">https://maevatrivino.itch.io/elixir-of-love</a>
</p-dialog>
