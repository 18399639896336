<p-dialog [modal]="true" [(visible)]="visible" [style]="{'width': '500px', 'overflow': 'auto'}"
          [draggable]="false" [responsive]="true"  [dismissableMask]="true" (onHide)="onHide()">
  <p-header>
    {{'PersonnalProjects.Yapalfeu.Title' | translate }}
  </p-header>

  <p align="justify" [innerHtml]="'PersonnalProjects.Yapalfeu.Description1' | translate"></p>
  <ul>
    <li>
      Nicolas Castano
    </li>
    <li>
      Juliette Laplante
    </li>
    <li>
      Maud Duraffourg
    </li>
    <li>
      Paul Du
    </li>
    <li>
      Yoann Haffner
    </li>
    <li>
      Théodore Tremblay
    </li>
  </ul>
  <p align="justify" [innerHtml]="'PersonnalProjects.Yapalfeu.Description2' | translate"></p>
  <strong>{{'PersonnalProjects.Yapalfeu.GithubLink' | translate }}</strong><a href="https://github.com/Maeva-Trivino/WonderJamAutomne" target="_blank">https://github.com/Maeva-Trivino/WonderJamAutomne</a>
</p-dialog>
