<p-dialog [modal]="true" [(visible)]="visible" [style]="{'width': '500px', 'overflow': 'auto'}"
          [draggable]="false" [responsive]="true"  [dismissableMask]="true" (onHide)="onHide()">
  <p-header>
    {{'PersonnalProjects.And.Title' | translate }}
  </p-header>
  <p align="justify" [innerHtml]="'PersonnalProjects.And.Description1' | translate">
  </p>
  <ul>
    <li>
      Damien Galochet
    </li>
    <li>
      Jean Farines
    </li>
    <li>
      Fabien Cellard
    </li>
    <li>
      Yann Royant
    </li>
    <li>
      Louis Cesaro
    </li>
    <li>
      Yannick Texier
    </li>
    <li>
      Baptiste Babu
    </li>
  </ul>
  <p align="justify" [innerHtml]="'PersonnalProjects.And.Description2' | translate"></p>
  <strong>{{'PersonnalProjects.And.ExecutableLink' | translate }}</strong><a href="https://maevatrivino.itch.io/and" target="_blank">https://maevatrivino.itch.io/and</a>
</p-dialog>
