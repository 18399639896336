<p-dialog [modal]="true" [(visible)]="visible" [style]="{'width': '500px', 'overflow': 'auto'}"
          [draggable]="false" [responsive]="true"  [dismissableMask]="true" (onHide)="onHide()">
  <p-header>
    {{'PersonnalProjects.GranniesWar.Title' | translate }}
  </p-header>

  <p align="justify" [innerHtml]="'PersonnalProjects.GranniesWar.Description1' | translate"></p>
  <ul>
    <li>
      Nicolas Castano
    </li>
    <li>
      Juliette Laplante
    </li>
    <li>
      Fabien Cellard
    </li>
    <li>
      Quentin Durand
    </li>
    <li>
      Yoann Haffner
    </li>
  </ul>
  <p align="justify" [innerHtml]="'PersonnalProjects.GranniesWar.Description2' | translate"></p>
  <strong>{{'PersonnalProjects.GranniesWar.GithubLink' | translate }}</strong><a href="https://github.com/Maeva-Trivino/WonderjamH2020" target="_blank">https://github.com/Maeva-Trivino/WonderjamH2020</a><br/><br/>
  <strong>{{'PersonnalProjects.GranniesWar.ExecutableLink' | translate }}</strong><a href="https://maevatrivino.itch.io/grannies-war" target="_blank">https://maevatrivino.itch.io/grannies-war</a>
</p-dialog>
