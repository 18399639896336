import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-elixir-of-love',
  templateUrl: './elixir-of-love.component.html',
  styleUrls: ['./elixir-of-love.component.scss']
})
export class ElixirOfLoveComponent implements OnInit {

  @Input()
  public visible: boolean;
  @Output()
  public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  onHide(){
    this.visible = false;
    this.visibleChange.emit(this.visible);
  }

}
