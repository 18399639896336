<p-dialog [modal]="true" [(visible)]="visible" [style]="{'width': '500px', 'overflow': 'auto'}"
          [draggable]="false" [responsive]="true"  [dismissableMask]="true" (onHide)="onHide()">
  <p-header>
    Apollo
  </p-header>

  <ul>
    <li [innerHtml]="'ExperiencePro.Apollo.Description' | translate"></li>
  </ul>

</p-dialog>
