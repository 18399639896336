import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-slider-menu',
  templateUrl: './slider-menu.component.html',
  styleUrls: ['./slider-menu.component.scss']
})
export class SliderMenuComponent implements OnInit {

  public valueDontUse: string = "en";
  
  constructor(
    public translate: TranslateService,
    private router: Router) 
  { 
    // Register translation languages
    this.translate.addLangs(['en', 'fr']);
    // Set default language
    this.translate.setDefaultLang('fr');
  }

  ngOnInit(): void {
  }

  translateLanguageTo(lang: string){
    this.translate.use(lang);

    switch(lang){
      case 'en':{
        this.valueDontUse = 'fr';
        break;
      }

      case 'fr':{
        this.valueDontUse = 'en';
        break;
      }
    }
  }

  getOnHomePage(){
    this.router.navigate(['/']);
  }

  getOnFormationsPage(){
    this.router.navigate(['/formations']);
  }

  getOnCompetencesPage(){
    this.router.navigate(['/competences']);
  }

  getOnExperiencesProPage(){
    this.router.navigate(['/experiencespro']);
  }

  getOnProjetsPage(){
    this.router.navigate(['/projets']);
  }

  getOnProjetsProPage(){
    this.router.navigate(['/projetspro']);
  }

  getOnEngagementAssoPage(){
    this.router.navigate(['/engagementasso']);
  }
}
