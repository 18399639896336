<p-dialog [modal]="true" [(visible)]="visible" [style]="{'width': '500px', 'overflow': 'auto'}"
          [draggable]="false" [responsive]="true"  [dismissableMask]="true" (onHide)="onHide()">
  <p-header>
    Broken Edge
  </p-header>

  <p align="justify" [innerHtml]="'ProfesionnalProjects.BrokenEdge.Description' | translate"></p>

  <p>
    <strong>{{'ProfesionnalProjects.BrokenEdge.SteamStore' | translate }} </strong> <a href="https://store.steampowered.com/app/1947070/Broken_Edge/" target="_blank">https://store.steampowered.com/app/1947070/Broken_Edge/</a><br/><br/>
    <strong>{{'ProfesionnalProjects.BrokenEdge.QuestStore' | translate }} </strong> <a href="https://www.oculus.com/experiences/quest/5136958036416423" target="_blank">https://www.oculus.com/experiences/quest/5136958036416423</a>
  </p>

</p-dialog>
