<div class="container-competences">
  <app-font-image></app-font-image>

  <div class="title-page">
    <h1>{{'SliderMenu.Skills' | translate }}</h1>
  </div>

  <div class="list-competences">
    <div class="element-competence">
      <div class="nom-competence">
        <i class="fa-2x fab fa-unity"></i>
        <div class="description-competence">
          <p>Unity</p>
        </div>
      </div>
      <app-progress-bar [heightValue]="24" [pourcentageValue]="90"></app-progress-bar>
    </div>

    <div class="element-competence">
      <div class="nom-competence">
        <i class="fa-2x devicon-csharp-plain"></i>
        <div class="description-competence">
          <p>C#</p>
        </div>
      </div>
      <app-progress-bar [heightValue]="24" [pourcentageValue]="90"></app-progress-bar>
    </div>

    <div class="element-competence">
      <div class="nom-competence">
        <i class="fa-2x devicon-azuredevops-plain"></i>
        <div class="description-competence">
          <p>Azure DevOps</p>
        </div>
      </div>
      <app-progress-bar [heightValue]="24" [pourcentageValue]="50"></app-progress-bar>
    </div>

    <div class="element-competence">
      <div class="nom-competence">
        <i class="fa-2x devicon-githubactions-plain"></i>
        <div class="description-competence">
          <p>Github Actions</p>
        </div>
      </div>
      <app-progress-bar [heightValue]="24" [pourcentageValue]="50"></app-progress-bar>
    </div>

    <div class="element-competence">
      <div class="nom-competence">
        <i class="fa-2x devicon-dotnetcore-plain"></i>
        <div class="description-competence">
          <p>.NET</p>
        </div>
      </div>
      <app-progress-bar [heightValue]="24" [pourcentageValue]="75"></app-progress-bar>
    </div>

    <div class="element-competence">
      <div class="nom-competence">
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
             width="32" height="32"
             viewBox="0 0 172 172"
             style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none"
                                       stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter"
                                       stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0"
                                       font-family="none" font-weight="none" font-size="none" text-anchor="none"
                                       style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z"
                                                                            fill="none"></path><g fill="#ffffff">
          <path d="M86,16.125c-38.52783,0 -69.875,31.34717 -69.875,69.875c0,38.52783
          31.34717,69.875 69.875,69.875c38.52783,0 69.875,-31.34717 69.875,-69.875c0,-38.52783
          -31.34717,-69.875 -69.875,-69.875zM86,26.875c32.71192,0 59.125,26.41309 59.125,59.125c0,32.71192
          -26.41308,59.125 -59.125,59.125c-32.71191,0 -59.125,-26.41308 -59.125,-59.125c0,-32.71191 26.41309,
          -59.125 59.125,-59.125zM86,43c-25.57324,4.51416 -48.375,28.09278 -48.375,53.75c12.51367,-22.08789
          20.87012,-23.68359 23.17969,-23.68359c2.30957,0 3.52734,1.19678 3.52734,3.86328v32.92188c0,4.09424
          -7.95752,3.42236 -10.58203,3.02344c9.72119,14.46631 32.25,16.125 32.25,16.125l10.75,-10.75l10.75,5.375c16.96484,
          -7.76855 26.875,-24.39746 26.875,-26.875c-7.36963,8.12549 -14.69727,10.75 -16.125,10.75c-1.42773,0 -5.375,-0.73486
          -5.375,-2.6875v-38.96875c0,-3.38037 7.81055,-13.71045 10.75,-17.46875c-19.40039,5.08106 -25.02734,13.94141
           -25.02734,13.94141c0,0 -1.46972,-3.19141 -7.22266,-3.19141c2.1416,1.84766 5.375,6.84473 5.375,10.75v32.92188c0,0
           -7.01269,4.70313 -10.75,4.70313c-3.7373,0 -5.54297,-1.51172 -5.54297,-4.36719c0,-2.85547 0.16797,-38.63281
           0.16797,-38.63281c0,0 -5.375,1.97364 -5.375,-9.07031c0,-5.52197 10.75,-12.42969 10.75,-12.42969z"></path></g></g></svg>
        <div class="description-competence">
          <p>Unreal Engine</p>
        </div>
      </div>
      <app-progress-bar [heightValue]="24" [pourcentageValue]="20"></app-progress-bar>
    </div>

    <div class="element-competence">
      <div class="nom-competence">
        <i class="fa-2x devicon-cplusplus-plain"></i>
        <div class="description-competence">
          <p>C++</p>
        </div>
      </div>
      <app-progress-bar [heightValue]="24" [pourcentageValue]="30"></app-progress-bar>
    </div>

    <div class="element-competence">
      <div class="nom-competence">
        <i class="fa-2x fab fa-angular"></i>
        <div class="description-competence">
          <p>Angular CLI</p>
        </div>
      </div>
      <app-progress-bar [heightValue]="24" [pourcentageValue]="75"></app-progress-bar>
    </div>

    <div class="element-competence">
      <div class="nom-competence">
        <i class="fa-2x fab fa-sass"></i>
      </div>
      <app-progress-bar [heightValue]="24" [pourcentageValue]="65"></app-progress-bar>
    </div>
    
  </div>

  <app-footer></app-footer>
</div>

<app-slider-menu></app-slider-menu>
