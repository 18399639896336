import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-experiences-pro',
  templateUrl: './experiences-pro.component.html',
  styleUrls: ['./experiences-pro.component.scss']
})
export class ExperiencesProComponent implements OnInit {

  public visibleCaeModal = false;
  public visibleTrebuchetModal = false;
  public visibleCollegeBoisBoulogneModal = false;
  public visibleMageUqacModal = false;
  public visibleApolloModal = false;
  public visibleIntermarcheModal = false;

  constructor() { }

  ngOnInit(): void {
  }

  clickCaeCard(){
    this.visibleCaeModal = true;
  }

  clickTrebuchetCard(){
    this.visibleTrebuchetModal = true;
  }

  clickCollegeBoisBoulogneCard(){
    this.visibleCollegeBoisBoulogneModal = true;
  }

  clickMageUqacCard(){
    this.visibleMageUqacModal = true;
  }

  clickApolloCard(){
    this.visibleApolloModal = true;
  }

  clickIntermarcheCard(){
    this.visibleIntermarcheModal = true;
  }

}
