<div class="container-formations">
  <app-font-image></app-font-image>

  <div class="title-page">
    <h1>{{'SliderMenu.Schooling' | translate }}</h1>
  </div>
  <div class="list-cards">
    <p-card header="{{'Schooling.UQAC.Title' | translate}}"
            subheader="{{'Schooling.UQAC.SubTitle' | translate}}"
            [style]="{width : '320px', height: '420px'}"
            styleClass="ui-card-shadow">
      <p-header>
        <img src="../../../assets/formations/logo-uqac.png">
      </p-header>
      <div [innerHtml]="'Schooling.UQAC.Description' | translate"></div>
    </p-card>

    <p-card header="{{'Schooling.PolytechLyon.Title' | translate}}"
            subheader="{{'Schooling.PolytechLyon.SubTitle' | translate}}"
            [style]="{width : '320px', height: '420px'}"
            styleClass="ui-card-shadow">
      <p-header>
        <img src="../../../assets/formations/polytech.png">
      </p-header>
      <div [innerHtml]="'Schooling.PolytechLyon.Description' | translate"></div>
    </p-card>

    <p-card header="{{'Schooling.PrepaPolytechLyon.Title' | translate}}"
            subheader="{{'Schooling.PrepaPolytechLyon.SubTitle' | translate}}"
            [style]="{width : '320px', height: '420px'}"
            styleClass="ui-card-shadow">
      <p-header>
        <img src="../../../assets/formations/polytech.png">
      </p-header>
      <div [innerHtml]="'Schooling.PrepaPolytechLyon.Description' | translate"></div>
    </p-card>
  </div>
  <app-footer></app-footer>
</div>

<app-slider-menu></app-slider-menu>
