<div class="container-experiences">
  <app-font-image></app-font-image>

  <div class="title-page">
    <h1>{{'SliderMenu.ProfessionalExperiences' | translate }}</h1>
  </div>
  <div class="list-cards">
    <p-card header="CAE"
            subheader="Montréal Qc, Canada"
            [style]="{width : '320px', height: '300px'}"
            styleClass="ui-card-shadow"
            (click)="clickCaeCard()">
      <p-header>
        <img class=img-cae src="../../../assets/experiences-pro/cae.png">
      </p-header>
    </p-card>

    <p-card header="Trebuchet"
            subheader="Montréal Qc, Canada"
            [style]="{width : '320px', height: '300px'}"
            styleClass="ui-card-shadow"
            (click)="clickTrebuchetCard()">
      <p-header>
        <img class=img-trebuchet src="../../../assets/experiences-pro/trebuchet.png">
      </p-header>
    </p-card>

    <p-card header="Collège de Bois-de-Boulogne"
            subheader="Montréal Qc, Canada"
            [style]="{width : '320px', height: '300px'}"
            styleClass="ui-card-shadow"
            (click)="clickCollegeBoisBoulogneCard()">
      <p-header>
        <img class=img-trebuchet src="../../../assets/experiences-pro/bdeb.png">
      </p-header>
    </p-card>

        <p-card header="Reprographie Etudiante du MAGE-UQAC"
                subheader="Chicoutimi, Qc, Canada"
                [style]="{width : '320px', height: '300px'}"
                styleClass="ui-card-shadow"
                (click)="clickMageUqacCard()">
      <p-header>
        <img id=img-mage src="../../../assets/experiences-pro/mage-uqac.png">
      </p-header>
    </p-card>

    <p-card header="Apollo"
            subheader="Lyon 3ème, France"
            [style]="{width : '320px', height: '300px'}"
            styleClass="ui-card-shadow"
            (click)="clickApolloCard()">
      <p-header>
        <img id="img-apollo" src="../../../assets/experiences-pro/apollo.png">
      </p-header>
    </p-card>

    <p-card header="Intermarché"
            subheader="Varennes-Sous-Dun, France"
            [style]="{width : '320px', height: '300px'}"
            styleClass="ui-card-shadow"
            (click)="clickIntermarcheCard()">
      <p-header>
        <img src="../../../assets/experiences-pro/itm_super.png">
      </p-header>
    </p-card>
  </div>

  <app-cae [(visible)]="visibleCaeModal"></app-cae>
  <app-intermarche [(visible)]="visibleIntermarcheModal"></app-intermarche>
  <app-apollo [(visible)]="visibleApolloModal"></app-apollo>
  <app-college-bois-boulogne [(visible)]="visibleCollegeBoisBoulogneModal"></app-college-bois-boulogne>
  <app-trebuchet [(visible)]="visibleTrebuchetModal"></app-trebuchet>
  <app-mage-uqac [(visible)]="visibleMageUqacModal"></app-mage-uqac>

  <app-footer></app-footer>
</div>

<app-slider-menu></app-slider-menu>
