import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-projets',
  templateUrl: './projets.component.html',
  styleUrls: ['./projets.component.scss']
})
export class ProjetsComponent implements OnInit {

  public visibleAndModal = false;
  public visibleTutoReactModal = false;
  public visibleGranniesWarModal = false;
  public visibleElixirOfLoveModal = false;
  public visibleYapalfeuModal = false;

  constructor() { }

  ngOnInit(): void {
  }

  clickAndProjectCard(){
    this.visibleAndModal = true;
  }

  clickTutoReactCard(){
    this.visibleTutoReactModal = true;
  }

  clickGranniesWarCard(){
    this.visibleGranniesWarModal = true;
  }

  clickElixirOfLoveCard(){
    this.visibleElixirOfLoveModal = true;
  }

  clickYapalfeuCard(){
    this.visibleYapalfeuModal = true;
  }

}
