<div class="container-projets">
  <app-font-image></app-font-image>

  <div class="title-page">
    <h1>{{'SliderMenu.PersonnalProjects' | translate }}</h1>
  </div>

  <div class="list-cards">

    <p-card header="{{'ProfesionnalProjects.BrokenEdge.Title' | translate }}"
            subheader="{{'ProfesionnalProjects.BrokenEdge.SubTitle' | translate }}"
            [style]="{width : '320px', height: '320px'}"
            styleClass="ui-card-shadow"
            (click)="clickBrokenEdgeCard()">
      <p-header>
        <img src="../../../assets/projets-pro/BrokenEdge.jpg">
      </p-header>
    </p-card>

    <p-card header="{{'ProfesionnalProjects.WindsAndLeaves.Title' | translate }}"
            subheader="{{'ProfesionnalProjects.WindsAndLeaves.SubTitle' | translate }}"
            [style]="{width : '320px', height: '320px'}"
            styleClass="ui-card-shadow"
            (click)="clickWindsAndLeavesCard()">
      <p-header>
        <img src="../../../assets/projets-pro/WindsAndLeaves.jpg">
      </p-header>
    </p-card>

  </div>

  <app-winds-and-leaves [(visible)]="visibleWindsAndLeavesModal"></app-winds-and-leaves>
  <app-broken-edge [(visible)]="visibleBrokenEdgeModal"></app-broken-edge>

  <app-footer></app-footer>


</div>

<app-slider-menu></app-slider-menu>
